.carousel__thumbs {
  justify-content: center;
  align-items: center;
  --grid-gap: 0.75rem;
}

.carousel__thumb {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  border: 3px solid var(--gray);

  transition: all 0.3s ease-in-out;

  cursor: pointer;

  &.active {
    transform: scale(1.5);
    border-color: var(--lachs);
  }
}

.carousel__slides-container {
  position: relative;
  max-width: var(--container-width);
  margin: var(--block-margin) auto;
}

.carousel__control--previous,
.carousel__control--next {
  position: absolute;
  top: calc(50% - 1.5rem);
  z-index: 1;

  background: none;
  border: none;

  cursor: pointer;

  --icon-size: 3rem;
  --icon-color: var(--gray-dark);

  &:hover {
    --icon-color: var(--gray);
  }
}

.carousel__control--previous {
  left: 0;
}
.carousel__control--next {
  right: 0;
}

@keyframes from-right {
  from {
    opacity: 0;
    transform: translateX(100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes from-left {
  from {
    opacity: 0;
    transform: translateX(-100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.carousel__slides-container {
  &.from-right .slide {
    opacity: 0;
    transform: translateX(100vw);
    animation: from-right 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  &.from-left .slide {
    opacity: 0;
    transform: translateX(-100vw);
    animation: from-left 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}
