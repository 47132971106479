footer {
  background-color: var(--gray-darker);
  background-image: url(/static/assets/BackgroundFooter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  color: var(--white);
  position: relative;

  font-size: 20px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 0;
    color: inherit;
    text-align: left;
  }
}

.footer__container--main {
  padding: var(--block-margin) 0;

  > .flex-grid {
    @media (--media-lg) {
      justify-content: space-between;
      align-items: center;
    }
  }
}

.footer__side-links-container {
  flex-direction: column;
  @media (--media-md) {
    flex-direction: row;
  }
  @media (--media-lg) {
    flex-direction: column;
  }
}

.footer__contact-container,
.footer__side-links-container,
.footer__some-container {
  margin: 1rem !important;
}

.footer__contact-container {
  --grid-gap: 3rem;
  .flex-grid {
    justify-content: flex-start;
    @media (--media-md) {
      justify-content: center;
    }
  }
}

.footer__some-container {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);

  a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    .icon {
      fill: white;
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.3rem;
    }
  }
}

.footer__container--bottom {
  padding: 0.5rem 0 3rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  a {
    color: var(--rhein-light);
    text-decoration: none;

    &:hover {
      color: var(--rhein);
    }
  }
}
