.context-page {
  font-size: 1.5rem;
  --prose-container-width: 42.875rem;

  main {
    overflow: hidden;
  }
}

#context-background {
  position: absolute;
  z-index: 0;
  overflow: hidden;
  margin: -780px auto 0 auto;
  width: 3000px;
  height: 5947px;
  inset: 0;

  transform: translateX(calc(-1500px + 50vw));
}

.hero {
  position: absolute;
  top: 0;
  height: 642px;
  width: 100%;
}

.hero .embedded-video {
  max-width: 800px;
  margin: var(--main-padding) auto;
}

.facts-and-figures-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  > .grid-item {
    flex: 0 1 18rem;
    align-items: center;
    justify-content: center;
  }
}
.milestones {
  margin-top: var(--block-margin);
  margin-bottom: var(--block-margin);
  max-width: var(--container-width);
}
.milestones .prose {
  padding: 2rem 3rem 1rem 3rem;
  @media (--media-md) {
    border-radius: 5px;
    padding: 3rem 4rem 2rem 4rem;
  }
  background: var(--lachs);
  color: var(--white);
}

.context-wrap {
  background-color: var(--rhein-dark);
  color: var(--white);

  > .container {
    padding-top: var(--block-margin);
    padding-bottom: var(--block-margin);
  }

  .button {
    --button-color: var(--rhein);
    font-size: 1.5rem;
  }
}

.container1 {
  margin-top: 5160px;
  position: relative;
}

.container1 .prose {
  --prose-container-width: var(--container-width);
  @media (--media-md) {
    columns: 2;
    p {
      font-size: 1.33rem;
      display: inline-block;
    }
  }
}

.milestone__container {
  margin-top: var(--block-margin);
  margin-bottom: var(--block-margin);
  @media (--media-md) {
    position: relative;
    width: 90%;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}

.milestone__image {
  max-width: 100%;
  overflow: hidden;
  border-radius: 5px;

  transform: translateY(2rem);

  &.top-left,
  &.bottom-left {
    img {
      margin-right: auto;
    }
  }
  &.top-right,
  &.bottom-right {
    img {
      margin-left: auto;
    }
  }

  @media (--media-lg) {
    position: absolute;
    transform: translateY(0);

    &.top-left {
      top: -3rem;
      left: 0;
    }
    &.top-right {
      top: -3rem;
      right: 0;
    }
    &.bottom-left {
      bottom: -3rem;
      left: 0;
    }
    &.bottom-right {
      bottom: -3rem;
      right: 0;
    }
  }
}

.milestone__details {
  overflow: hidden;
  border-radius: 5px;

  .top-left + &,
  .bottom-left + & {
    margin-left: auto;
  }
  .top-right + &,
  .bottom-right + & {
    margin-right: auto;
  }
  position: relative;
  max-width: 38rem;
  background: var(--white);
  padding: 2.5rem 2.5rem 1.5rem;

  h3 {
    font-family: PlayfairDisplay;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    strong {
      color: var(--lachs);
    }
  }
}
