.hero-context-overlay__container {
  @media (--media-md) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.hero-context-overlay {
  margin-left: auto;
  width: 15rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;

  background: var(--lachs);
  color: var(--white);

  @media (--media-md) {
    transform: translateY(-10rem);
    animation: overlay-dropdown 0.3s ease-in 1s forwards;
  }

  .overlay-text {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    line-height: 1.3;
  }

  .button--arrow {
    font-size: 1.3rem;
    --icon-size: 1rem;

    font-weight: bold;

    padding: 1.5rem 1.5rem;

    --button-color: var(--lachs-dark);
  }
}

main > .no-hero:first-of-type {
  margin-top: 7rem;
}

@keyframes overlay-dropdown {
  from {
    transform: translateY(-10rem);
  }
  to {
    transform: translateY(0);
  }
}
