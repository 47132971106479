.river__content {
  .year-block {
    .container {
      --container-width: 1200px;
    }
    position: absolute;
    width: 100%;

    &.block-0 {
      top: 900px;
    }
    &.block-1 {
      top: 1760px;
    }
    &.block-2 {
      top: 3940px;
    }
    &.block-3 {
      top: 4900px;
    }

    &__year {
      font-family: PlayfairDisplay, serif;
      font-weight: bold;
      font-size: 3rem;
      display: none;
      @media (--media-md) {
        display: block;
      }
    }

    &__line {
      border-bottom: 3px dashed var(--gray);
      transform: rotate(-5deg);
      transform-origin: 10%;
      display: none;

      @media (--media-md) {
        display: block;
      }
    }
  }

  .story-block {
    position: absolute;
    width: 100%;

    font-size: 1rem;

    @media (--media-md) {
      font-size: 1.5rem;
    }

    .block-container {
      position: relative;
      max-width: calc(100% - 2rem);
      width: 37.5rem;

      opacity: 0;
    }

    .block {
      position: relative;
    }

    .block-text {
      position: relative;
      z-index: 3;
      color: var(--white);
      padding: 2rem 2rem 1rem 2rem;
      border: 1px solid var(--gray);
      border-radius: 5px;
    }

    .block-background {
      position: absolute;
      z-index: 2;
      inset: 0;
      background-color: var(--gray-darker);
      border-radius: 5px;
    }

    &.block-0 {
      top: 941px;
      .block-background {
        transform: rotate(-3deg);
      }
      .block-container {
        margin-left: auto;
      }
      .image-0 {
        top: -197px;
        right: -35px;
      }
      .image-1 {
        z-index: 2;
        bottom: -180px;
        right: -104px;
      }
    }

    &.block-1 {
      top: 1505px;
      .block-background {
        transform: rotate(3deg);
      }
      .block-container {
        margin-right: auto;
      }
      .image-0 {
        display: none;
        top: -427px;
        left: -135px;
        @media (--media-md) {
          display: block;
        }
      }
      .image-1 {
        top: -281px;
        left: -60px;
      }
      .image-2 {
        right: -50px;
        bottom: -160px;
        @media (--media-md) {
          right: -551px;
        }
      }
    }

    &.block-2 {
      top: 2321px;
      .block-background {
        transform: rotate(-3deg);
      }
      .image-0 {
        top: -197px;
        right: -35px;
      }
    }

    &.block-3 {
      top: 2946px;
      .block-background {
        transform: rotate(3deg);
      }
      .block-container {
        margin-left: auto;
      }
      .image-0 {
        top: -227px;
        left: -600px;
      }
      .image-1 {
        bottom: -241px;
        right: -120px;
      }
    }

    &.block-4 {
      top: 3424px;
      .block-background {
        transform: rotate(-3deg);
      }
    }

    &.block-5 {
      top: 4037px;
      .block-container {
        margin-left: auto;
      }
      .block-background {
        transform: rotate(3deg);
      }
      .image-0 {
        top: -227px;
        right: -100px;
      }
    }

    &.block-6 {
      top: 4602px;
      .block-background {
        transform: rotate(-3deg);
      }
      .image-0 {
        top: -150px;
        left: -30px;
        @media (--media-md) {
          top: -230px;
          left: -230px;
        }
      }
      .image-1 {
        bottom: -184px;
        right: -60px;
      }
    }

    .block-image {
      position: absolute;
    }
  }
  .animate {
    animation: block-fade-in 2s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
}
