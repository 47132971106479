html {
  background: var(--gray-darker);
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  overflow-x: hidden;
}

main {
  background-color: var(--gray-lighter);
  background-image: url(/static/assets/Background.png);
  background-repeat: repeat-y;
  background-position-x: center;
  background-size: 100vw;

  flex: 1 0 auto;
  padding-bottom: var(--main-padding);

  position: relative;
}

.container {
  max-width: var(--container-width);
  width: 100%;

  padding-left: 1rem;
  padding-right: 1rem;

  .container:not(.container--content) {
    padding-left: 0;
    padding-right: 0;
  }

  &--content {
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    background: var(--white);
    padding-bottom: var(--main-padding);

    @media (--media-md) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    @media (--media-lg) {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    & > *:first-child:not(.lead-image) {
      padding-top: var(--main-padding);
    }
  }

  &--wrap {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    @media (--media-md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

main > .container:not(.container--wrap) {
  margin-top: var(--block-margin);
  margin-bottom: var(--block-margin);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.prose {
  --container-width: var(--prose-container-width);
}

.rhein-border {
  border-top: 3px solid var(--rhein);
  &--light {
    border-top: 3px solid var(--rhein-light);
  }
}

.content-page {
  main {
    background: var(--gray-light);
    background-image: none;
    padding-bottom: 0;
  }

  .container--content > *:first-child:not(.lead-image) {
    padding-top: var(--main-padding);
  }
}

.two-columns {
  display: flex;
  flex-direction: column;

  @media (--media-lg) {
    flex-direction: row-reverse;
    justify-content: space-between;

    .left-column {
      width: calc(68% - 1.5rem);
      margin-right: 1.5rem;
    }

    .right-column {
      width: 32%;
    }
  }
}
