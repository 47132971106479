.support-button {
  --size: 100px;

  height: var(--size);
  width: var(--size);
  display: block;
  position: absolute;

  background-color: var(--lachs-light);
  border-radius: 50%;
  animation: float 4s ease-in-out infinite;
  z-index: 100;

  box-shadow:
    0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);

  background-image: url("/static/assets/support-button.svg");

  cursor: url("data:image/svg+xml,%3Csvg id='support-button-heart' width='18' height='24' viewBox='0 0 27 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.47 30.76C12.78 27.19 8.97001 22.8 6.44001 17.45C5.91001 16.33 5.47001 15.21 5.03001 14.05C4.18001 11.79 2.25001 8.9 4.75001 11.64C6.85001 13.94 8.59001 16.68 10.48 19.15C10.88 19.67 13.39 21.41 13.66 19.86C14.16 17 14.67 14.08 15.74 11.36C16.27 10.01 17.16 7.85 18.38 6.81C19.4 5.25 20.91 5 22.93 6.07C23.01 7.01 23.01 7.96 22.91 8.9C22.54 17.11 18.77 24.69 15.1 31.87C14.27 33.5 17.91 36.31 18.62 34.93C21.64 29.01 24.73 22.97 26.11 16.41C26.99 12.24 27.93 6.48 24.81 2.98C16.52 -6.32 10.78 11.98 9.83001 17.42L13.01 18.13C10.59 14.95 7.77001 9.09 4.06001 7.34C1.39001 6.08 0.180011 7.98 0.550011 10.54C1.82001 19.17 9.16001 27.59 15.82 32.66C18.02 34.34 19.74 32.49 17.47 30.76Z' fill='%23D37C64' /%3E%3C/svg%3E"),
    pointer;

  top: 70vh;
  right: 3vw;

  @media (--media-md) {
    --size: 110px;
    top: 25vh;
    right: 5vw;
  }

  @media (--media-lg) {
    --size: 137px;
    top: 45vh;
    right: 5vw;
  }

  &::before {
    content: "";
    height: 36px;
    width: 27px;
    display: block;
    position: absolute;
    bottom: 12%;
    right: 19%;
    z-index: 1;
    background: url("/static/assets/support-button-heart.svg") center center
      no-repeat;
    background-size: contain;
    animation: 4s ease 0s infinite beat;
  }

  &::after {
    content: "";
    height: calc(var(--size) - 20px);
    width: calc(var(--size) - 20px);
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
    border-radius: 50%;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    z-index: 2;

    box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-100px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes beat {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  30%,
  80% {
    transform: scale(0.8);
  }
}
