:root {
  --icon-color: var(--gray);
  --icon-size: 1.5rem;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  fill: var(--icon-color);
  color: var(--icon-color);

  height: var(--icon-size);
  width: var(--icon-size);
}

.wwf-icon {
  background-image: url(/static/assets/wwf-icon.png);
}

.salmoncomeback-icon {
  background-image: url(/static/assets/salmoncomeback-icon.png);
}

.stroke-coloring {
  fill: none;
  stroke: var(--icon-color);
}
