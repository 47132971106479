.testimonials {
  & h2 {
    text-align: left;
  }
}

.testimonials-list {
  display: grid;

  @media (--media-md) {
    grid-template-columns: 6fr 6fr;
  }

  @media (--media-lg) {
    grid-template-columns: 8fr 4fr;
  }
}

.testimonials-list__text {
  margin-bottom: 5rem;

  & h1 {
    margin-bottom: 18px;
  }
  & p {
    margin-bottom: 66px;
  }
}

.testimonials-count {
  position: relative;
  width: 329px;
  height: 368px;
  margin: 0 auto;
}

.testimonials-count__number {
  position: absolute;
  bottom: 49.5%;
  left: 50%;
  transform: translateX(-50%);

  font-family: PlayfairDisplay;
  font-style: normal;
  font-weight: 700;
  font-size: 195px;
  line-height: 121.7%;
  text-align: center;

  color: var(--gray-darker);

  &::after {
    content: "";
    background: url("/static/assets/lachs-supporter-hearth.svg") center center
      no-repeat;
    background-size: 68px 83px;
    width: 68px;
    height: 83px;
    display: block;
    position: absolute;
    right: -1rem;
    top: 10px;
    transform: translate(100%, 50%);
  }
}

#testimonials-count__counter-fg {
  display: block;
  width: 277px;
  height: 115px;
  position: absolute;
  bottom: 90px;
  left: 74px;
  z-index: 1;
}

#testimonials-count__counter-bg {
  display: block;
  width: 329px;
  height: 368px;
}

.testimonials-filter {
  margin-bottom: 125px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.testimonials-filter__button {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 121.7%;
  padding: 3px 12px 2px 12px;
  display: inline-block;
}

.testimonials-grid {
  display: flex;
  gap: 24px;
}

.testimonials-grid__column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.testimonials-grid__item {
  width: 100%;
  position: relative;
  padding-right: 22px;
  padding-top: 115px;
  padding-bottom: 30px;
  border-radius: 15px;
  background-image: url("/static/assets/lachs_bg.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonials-grid__item-inner {
  background-color: white;
  position: relative;
  padding: 39px 18px 14px 14px;
  border-radius: 15px 15px 14px 15px;
}

.testimonials-grid__image {
  position: absolute;
  width: 128px;
  height: 128px;
  right: 25px;
  top: 0px;
  transform: translateY(-50%);
  border-radius: 50%;
  display: block;
}
.testimonials-grid__name {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 27px;
  margin-bottom: 14px;
}
.testimonials-grid__profession {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 44.2%;
  color: var(--lachs);
  margin-bottom: 18px;
}
.testimonials-grid__statement {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135.2%;
  margin: 25px 0;
}
.testimonials-grid__no-statement {
  width: 100%;
  margin: 31px 0 47px 0;
}

.testimonials-grid__separator {
  border: 1px solid var(--gray-light);
  transform: matrix(1, 0, 0, 1, 0, 0);

  margin: 15px 0px;
}

.testimonials-grid__sharing {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;

  > a {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
  }

  svg {
    color: var(--gray-darker);
    fill: var(--gray-darker);
  }
}

.image-cropping {
  margin-bottom: 1rem;
}

.image-cropping {
  display: none;
  max-width: 100%;
  &--show {
    display: block;
  }
}

.testimonial-create {
  background: #ffffff;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;

  & button[type="submit"] {
    height: 45px;
    background: var(--lachs);
    width: 100%;
    text-align: right;
    border: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 135.2%;

    text-align: right;

    color: #ffffff;
    margin-top: 24px;
    padding-right: 35px;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      background: url("/static/assets/arrow_right.svg") center center no-repeat;
      background-size: contain;
      height: 14px;
      width: 14px;
      display: block;
      pointer-events: none;
    }
  }

  input:not([type="checkbox"]),
  select,
  textarea {
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--gray);
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135.2%;
    padding: 6px 11px 6px 11px;
    margin-bottom: 9px;

    &:focus-visible {
      outline: var(--lachs-light) auto 1px;
    }

    &::placeholder {
      font-family: PragatiNarrow;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135.2%;
      color: var(--gray);
      opacity: 0.8;
    }
  }

  .widget--textinput,
  .widget--textarea,
  .widget--emailinput {
    label {
      display: none;
    }
  }

  /* select {
    position: relative;
    appearance: none;

    cursor: pointer;

    &::after {
      position: absolute;
      content: "test";
      background: red url("/static/assets/arrow_down.svg") center center
        no-repeat;
      background-size: contain;
      right: 0;
      top: 0;
      height: 14px;
      width: 14px;
    }
  } */

  input[type="checkbox"] {
    /* visibility: hidden; */
    height: 13px;
    width: 13px;
    display: block;
    margin-bottom: 9px;
    cursor: pointer;
  }

  & small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135.2%;
    color: var(--gray-darker);
    margin-left: 11px;
    display: block;
    margin-bottom: 24px;
  }

  & input[type="file"] {
    border: none;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135.2%;
    text-align: left;
    color: var(--gray-darker);
    margin-bottom: 10px;

    &::-webkit-file-upload-button,
    &::file-selector-button {
      background: var(--rhein-dark);
      border-radius: 64px;
      font-family: PragatiNarrow;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1em;
      text-align: center;
      color: #ffffff;
      border: none;
      padding: 12px 35px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135.2%;
    margin-bottom: 10px;
    padding-left: 11px;
  }
}
.testimonial-create__inner {
  padding: 34px 34px 0 38px;
}

.testimonial-create__required {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135.2%;

  color: var(--gray-darker);
  margin-bottom: 1rem;
  margin-left: 11px;
}

@media (--media-md) {
  .field-50-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

.widget--checkboxinput > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;

  & input {
    flex: 1 0 1rem;
  }
  & label {
    padding-left: 0;
  }
}

.testimonial-confirm {
  margin-top: 4.25rem;
  background: var(--gray-light);
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
}

.testimonial-confirm__image-preview {
  margin-bottom: 1rem;
  border-radius: 15px;
  overflow: hidden;
}

.testimonial-confirm__inner {
  padding: 22px 44px;

  @media (--media-lg) {
    padding: 44px 88px;
  }

  @media (--media-sm-only) {
    padding: 11px 22px;
  }
}

.testimonial-confirm__button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;

  :first-child {
    padding-right: 0;
    padding-left: 35px;
    text-align: left;
    justify-content: flex-start;

    &::after {
      content: "";
      position: absolute;
      left: 1rem;
      right: auto;
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.testimonial-confirm__button,
.testimonial-create button[type="submit"] {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--lachs);
  width: 100%;
  text-align: right;
  border: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135.2%;

  color: #ffffff;
  margin-top: 24px;
  padding-right: 35px;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    background: url("/static/assets/arrow_right.svg") center center no-repeat;
    background-size: contain;
    height: 14px;
    width: 14px;
    display: block;
    pointer-events: none;
  }

  &:hover {
    background-color: var(--lachs-light);
    text-decoration: none;
  }
}

ul.errorlist {
  color: var(--lachs-dark);
  margin-bottom: 0.5rem;
  line-height: 1.25rem;
}
