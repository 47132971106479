.f3cc {
  --f3cc-background: var(--gray-light);
  --f3cc-foreground: var(--black);
  --f3cc-button-background: var(--gray);
  --f3cc-accept-background: var(--rhein);
  --f3cc-button-foreground: var(--black);
  --f3cc-accept-foreground: var(--black);
}

.f3cc-button {
  border-radius: 2rem;
  padding: 0.3rem 2rem;
}
