#gallery-app {
  .carousel {
    position: relative;
  }
  .carousel__slides-container {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    max-width: var(--max-content-width);
  }
  .testimonial-slide__info {
    position: absolute;

    bottom: 1rem;
    width: 100%;

    @media (--media-md) {
      top: 0;
      right: 0;

      width: 60%;
      height: 100%;
    }

    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    text-shadow: 0 0 2.5rem var(--black);

    .testimonial-slide__info__text {
      font-size: 1.2rem;
      @media (--media-lg) {
        font-size: 2.125rem;
      }
      line-height: 1.2;
      margin-bottom: 1rem;
      color: var(--white);
    }
    .testimonial-slide__info__name {
      font-size: 0.8rem;
      @media (--media-lg) {
        font-size: 1.2rem;
      }
      color: var(--rhein);
    }
  }
  .carousel__thumbs {
    position: absolute;
    bottom: 1.5rem;
    width: 100%;
  }
}
