.custom-teaser {
  background: var(--lachs);
  color: var(--white);

  h2 {
    padding: 1rem 2.8125rem;
    text-align: left;
    font-family: PragatiNarrow, Helvetica, Arial, sans-serif;
    font-size: 1.75rem;
    margin: 0;
  }

  p {
    padding: 0 2.8125rem 2rem;
    white-space: break-spaces;
    margin: 0;
    font-size: 1.3rem;
  }

  .button {
    --button-color: var(--lachs-dark);
    font-size: 1.3rem;
    --icon-size: 1rem;
    font-weight: bold;
  }
}
