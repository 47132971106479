:root {
  --navbutton-size: 3rem;
  --nav-shadow: 0px 1px 3px var(--gray-dark);
}

.nav-border__container {
  box-shadow: var(--nav-shadow);

  width: 100%;
  height: 100%;
}

.logo-box {
  position: absolute;
  margin-top: 1rem;
  height: 8rem;
  width: 8rem;
  background: var(--white);
  border-radius: 50%;
  z-index: 100;

  box-shadow: var(--nav-shadow);
}

.logo {
  position: absolute;
  margin-top: 1rem;
  height: 8rem;
  width: 8rem;
  z-index: 120;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
  background-image: url(/static/assets/LachsComeback_Logo-12_2.png);
  background-size: contain;
}

.menu-is-open {
  overflow: hidden;
}

.mobile-menu {
  position: absolute;

  @media (--media-lg) {
    display: none;
  }

  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;

  z-index: 115;

  background: var(--white);

  padding: 1rem 0;

  transition: transform 0.3s ease-in-out;
  transform: translateY(-150vh);
}

.menu-is-open .mobile-menu {
  transform: translateY(0);
}

header {
  display: flex;
  font-size: 1.2rem;
  background: var(--white);
  z-index: 1;
}

.header__container {
  background: var(--white);
  position: relative;

  min-height: 5rem;

  z-index: 110;

  display: flex;
  align-items: center;
}

.mobile-menu__container {
  display: flex;
  flex-direction: column;
}

/* reset */
nav {
  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    color: var(--gray-darker);
    text-decoration: none;

    &.active:not(.button, .highlight) {
      border-bottom: 2px solid var(--lachs);
    }

    &:hover,
    &:focus-visible {
      color: var(--lachs);
      text-decoration: none;
    }

    &.highlight {
      display: flex;
      &:hover,
      &:focus-visible,
      &.active {
        filter: drop-shadow(2px 2px 1px var(--gray-darker));
      }
    }
  }
}

.main-nav {
  display: none;
  margin-left: auto;

  @media (--media-lg) {
    display: block;
  }

  > ul {
    margin: 0 -1rem;
    display: flex;
    align-items: center;
    > li {
      padding: 1rem 0;
      margin: 0 1rem;
    }
  }
}

.mobile-nav {
  margin: 6rem auto;
  > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    > li {
      margin: 1rem 0;
      /* > a {
        color: var(--white);
      } */
    }
  }

  /* .button--gray {
    --button-color: var(--white);
    --button-color-contrast: var(--black);
  } */
}

.nav-toggle {
  margin-left: auto;
  display: block;

  @media (--media-lg) {
    display: none;
  }

  > .button {
    background: var(--white);
    border: none;

    padding: 0;
    display: flex;
    align-items: center;

    border-radius: 50%;

    .icon {
      --icon-size: var(--navbutton-size);
      --icon-color: var(--gray-darker);
    }
  }
}
