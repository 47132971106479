:root {
  --button-color: var(--rhein-dark);
  --button-color-contrast: var(--white);
  --button-background-override: transparent;
}

.button {
  display: inline-block;
  border-radius: 2rem;
  padding: 0.3rem 2rem;
  background: var(--button-color);

  cursor: pointer;

  font-size: 1.25rem;
  text-align: center;
  text-decoration: none;
  color: var(--button-color-contrast);
  border: 1px solid transparent;

  &:hover,
  &:focus-visible {
    background: var(--button-color-contrast);
    color: var(--button-color);
    border-color: var(--button-color);
  }
}
.button--inline {
  padding: 0rem 1rem;
  font-size: 1rem;
  margin-left: 0.5rem;
}

.button--arrow {
  border: none;
  border-radius: 0;

  font-size: 1rem;

  display: flex;
  align-items: center;
  height: 3rem;

  padding: 0.5rem 1.5rem;

  --icon-size: 1rem;
  --icon-color: var(--button-color-contrast);

  .icon {
    transform: translateX(0.7rem);
  }

  &.full {
    width: 100%;
    & > *:first-child {
      margin-left: auto;
    }
  }

  &:hover,
  &:focus-visible {
    --icon-color: var(--button-color);
  }
}

.button--outline,
.button--filtering {
  border: 1px solid var(--button-color);
  color: var(--button-color);
  background: var(--button-background-override);

  &:hover,
  &.active,
  &:focus-visible {
    background: var(--button-color);
    color: var(--button-color-contrast);
  }
}

.button--rhein-light {
  --button-color: var(--rhein-light);
  --button-color-contrast: var(--black);
}

.button--lachs {
  --button-color: var(--lachs);
  --button-color-contrast: var(--white);
}

.button--gray {
  --button-color: var(--gray-darker);
  --button-color-contrast: var(--white);
}

.button--filtering {
  font-size: 1rem;
  padding: 0.2rem 1.5rem;

  --button-background-override: var(--white);
}

.button {
  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}
