:root {
  --grid-gap: 0.5rem;
  --grid-columns: 3;
  --grid-item: 10rem;
}

/* .masonry-grid {
  columns: var(--grid-columns);
  column-gap: var(--grid-gap);
  & > * {
    display: inline-block;
    margin-bottom: var(--grid-gap);
  }
} */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item), 1fr));
  grid-gap: var(--grid-gap);

  .grid-item {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--grid-gap);
  /* margin: calc(-1 * var(--grid-gap)); */
  /* & > *:not(.flex-grid) {
    margin: var(--grid-gap);
  } */
  &.column {
    flex-direction: column;
  }
  /* &.margins {
    margin: var(--grid-gap);
  } */
}
