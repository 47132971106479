.operation {
  display: flex;
  overflow: hidden;

  background: var(--white);

  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus-visible {
    .operation__arrow {
      background: var(--rhein-dark);
    }
  }
}

.operation__info {
  padding: 1rem 1.5rem 1.75rem;

  p {
    margin-bottom: 0;
  }
}

.operation__image {
  min-width: 8rem;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.operation__arrow {
  margin-left: auto;
  min-width: 2.25rem;
  background: var(--rhein);

  display: flex;
  align-items: center;

  --icon-size: 1rem;
  --icon-color: var(--white);

  .icon {
    margin: auto;
  }
}

.operation-detail {
  .lead-image {
    margin-bottom: var(--block-margin);
  }
}

.operations__filter-container {
  margin-bottom: var(--block-margin);
}

.operations__group-filter-container,
.operations__type-filter-container {
  justify-content: left;
  margin-bottom: 0.5rem;

  --grid-gap: 0.2rem;
}

.operations__type-filter-container .button {
  --button-color: var(--lachs);
}
