.map__map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.map__container {
  position: relative;
  height: 450px;
  @media (--media-lg) {
    height: 910px;
  }
}

.map__map-markers__overlay {
  > .container {
    display: flex;
    justify-content: left;
    flex-direction: column;
  }
}

.map__map-markers__active__container {
  .container {
    position: relative;
  }

  .marker-details {
    position: absolute;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;

    max-height: 450px;

    @media (--media-lg) {
      max-height: 910px;
      border-radius: 0px 0px 5px 5px;
      max-width: 21.3125rem;
      display: block;
    }

    background: var(--white);
    line-height: 1.3;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);

    .button--close {
      position: absolute;
      right: 0;
      padding: 1rem;

      border: none;
      background: none;

      --icon-size: 2rem;
      --icon-color: var(--black);
    }
  }

  .marker-details__image {
    padding: 2.35rem 1rem 1.35rem 2.175rem;
    flex: 0.5;
    display: flex;
    align-items: center;

    @media (--media-lg) {
      padding: 3.375rem 3.375rem 0;
    }
    img {
      margin: auto;
    }
  }

  .marker-details__image + .marker-details__details {
    padding-left: 0;
    @media (--media-lg) {
      padding-left: 3.375rem;
    }
  }

  .marker-details__details {
    padding: 3.35rem 2.175rem 1.35rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--media-lg) {
      padding: 2.35rem 3.375rem;
    }

    h3 {
      margin-bottom: 1em;
    }
  }
  .marker-details__location {
    padding: 1.35rem 2.175rem;
    background: var(--lachs);
    color: var(--white);
    width: 100%;

    @media (--media-lg) {
      padding: 2.35rem 3.375rem;
    }

    h4 {
    }
    p {
      margin-bottom: 0;
    }
  }
  .marker-details__some {
    padding: 1rem 3.375rem;
    width: 100%;
    display: flex;
    justify-content: center;
    & > * {
      margin: 0 0.5rem;
    }
    .icon:hover,
    .icon:focus-visible {
      --icon-color: var(--gray-dark);
    }
  }
}

.map-marker,
.map-cluster {
  cursor: pointer !important;
  .icon {
    --icon-size: 2.2rem;
  }
}

.map__map-markers__filter__container {
  margin-top: 1rem;
  @media (--media-md) {
    margin-top: var(--block-margin);
  }
  justify-content: left;
}

.map__map-markers__controls {
  justify-content: left;
  margin-top: 1rem;

  .button {
    --icon-color: var(--button-color-contrast);
    --icon-size: 1.5rem;

    padding: 0.3rem;
    display: flex;
    align-items: center;

    &:hover,
    &.icon:focus-visible {
      --icon-color: var(--button-color);
    }
  }
}

.map__map-markers__filter__container,
.map__map-markers__controls {
  .button {
    font-size: 0.8rem;
    --icon-size: 1rem;
    @media (--media-md) {
      font-size: 1rem;
      --icon-size: 1.5rem;
    }
  }
}

.map-marker.project {
  --icon-color: var(--rhein);
  &:hover,
  &.active {
    --icon-color: var(--rhein-dark);
  }
}

.map-marker.product {
  --icon-color: var(--lachs);
  &:hover,
  &.active {
    --icon-color: var(--lachs-dark);
  }
}

.map__map-markers__card-list__container {
  margin: var(--block-margin) auto calc(2 * var(--block-margin)) auto;
}

.card-list {
  --grid-gap: var(--block-margin);
  grid-template-columns: 1fr;

  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(var(--grid-item), 1fr));
  }
}

.marker-card {
  display: flex;
  flex-wrap: wrap;

  @media (--media-md) {
    flex-wrap: nowrap;
  }

  padding: 1rem;
  background: var(--white);

  .marker-card__image {
    flex: 2 1 100%;
    display: flex;
    align-items: center;

    img {
      padding: 1rem;
      margin-left: auto;
      margin-right: auto;
      max-height: 100%;
    }
  }

  .marker-card__info {
    flex: 1 1 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: var(--gray-darker);

    h3 {
      font-family: PlayfairDisplay;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }

  /* .marker-card__some {
    position: absolute;
    bottom: 0;

    border-top: 1px solid var(--gray);
    padding: 0.2rem;
    --grid-gap: 0.5rem;

    height: 2.7rem;

    width: calc(100% - 2rem);
    justify-content: right;

    a {
      display: flex;
      align-items: center;
    }

    .icon {
      fill: var(--gray);

      width: 1.5rem;
      height: 1.5rem;
    }
  } */
}

.marker-details__info {
  font-size: 1.3rem;
}
