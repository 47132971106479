.content-table {
  display: flex;
  width: 100%;
  margin: 1.5rem 0;

  .separator {
    width: 100%;
    border-top: 1px solid var(--gray);
  }

  h2 {
    font-size: 1rem;
    font-family: PragatiNarrow;
    margin: 0;
    line-height: 1.2;
    text-align: left;

    @media (--media-md) {
      width: 33%;
    }
  }
}

.content-button {
  text-align: center;
  margin-bottom: 1.5rem;
}

.operations-form-app {
  margin-bottom: 1.5rem;
}

.lead .prose {
  --prose-container-width: 42.8rem;

  h1,
  h2,
  h3,
  h4 {
    font-family: PlayfairDisplay, serif;
  }

  p {
    text-align: center;
  }
}

.lead {
  &.intersection-animate .prose {
    opacity: 0;
  }
  &.animate .prose {
    animation: block-fade-in 2s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
}

figcaption {
  text-align: right;
  font-size: 0.8rem;
}

.content--location {
  width: 100%;
}

.inlinemap {
  height: 360px;
}

.simple-location-marker {
  svg {
    fill: var(--lachs);
  }
}

.download-link {
  margin-block: 1.3rem;
  --icon-color: var(--black);
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.embedded-video {
  margin-block: 1.3rem;
}

.video {
  max-width: 100%;
}

.video-wrap {
  margin-block: 1.3rem;
  .download-link {
    margin-block: 0;
  }
}
