:root {
  --prose-container-width: 36rem;

  --container-width: 640px;
  --max-content-width: 100%;
  @media (--media-md) {
    --container-width: 768px;
  }
  @media (--media-lg) {
    --container-width: 1024px;
    --max-content-width: 1440px;
  }

  --block-margin: 4rem;
  --main-padding: 5.25rem;

  --black: #111111;
  --gray-lighter: #fafafa;
  --gray-light: #eaeaea;
  --gray: #d4d4d4;
  --gray-dark: #7a7a7a;
  --gray-darker: #404040;
  --white: #ffffff;

  --lachs-light: #ffcec0;
  --lachs: #eea894;
  --lachs-dark: #d37c64;

  --rhein-light: #88f1ee;
  --rhein: #42ccc8;
  --rhein-dark: #14928e;
}

.card {
  box-shadow: 1px 1px 3px var(--gray);
  border-radius: 5px;
  overflow: hidden;

  a&:hover,
  a&:focus-visible {
    box-shadow: 1px 1px 5px var(--black);
  }
}

.some-links-include {
  display: flex;
  align-items: center;
}

a[data-description]::after {
  content: attr(data-description);
  position: absolute;
  background: var(--gray-darker);
  color: var(--white);

  white-space: nowrap;

  font-size: 1rem;
  padding: 0.5rem;

  animation: fade-out 1s cubic-bezier(0.165, 0.84, 0.44, 1) 2s forwards;
}

.flex {
  display: flex;
  gap: var(--grid-gap);
  align-items: center;
}

.show-more {
  margin: 2rem auto;
  text-align: center;
}

@keyframes block-fade-in {
  from {
    opacity: 0;
    transform: translateY(300px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
}

.embedded-video__container > div {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.6615620214%;
}

.embedded-video__container > div iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
