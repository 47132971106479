@font-face {
  font-family: PragatiNarrow;
  font-weight: normal;
  font-style: normal;
  src: url(/fonts/PragatiNarrow-Regular.woff2) format("woff2");
}
@font-face {
  font-family: PragatiNarrow;
  font-weight: bold;
  font-style: normal;
  src: url(/fonts/PragatiNarrow-Bold.woff2) format("woff2");
}
@font-face {
  font-family: PlayfairDisplay;
  font-weight: normal;
  font-style: normal;
  src: url(/fonts/PlayfairDisplay-VariableFont_wght.woff2) format("woff2");
}
@font-face {
  font-family: PlayfairDisplay;
  font-weight: normal;
  font-style: italic;
  src: url(/fonts/PlayfairDisplay-Italic-VariableFont_wght.woff2)
    format("woff2");
}

html {
  font-family: PragatiNarrow;
  color: var(--gray-darker);
}

.serif {
  font-family: PlayfairDisplay;
}

.lachs {
  color: var(--lachs);
}

.rhein {
  color: var(--rhein);
}

.rhein-dark {
  color: var(--rhein-dark);
}

.center,
.lead h2 {
  text-align: center;
}

h1,
h2 {
  font-size: 3rem;
  margin: var(--block-margin) 0 2.625rem;
  font-family: PlayfairDisplay, serif;
  color: inherit;
}

h3 {
  font-size: 1.5rem;
  font-weight: normal;
  color: inherit;
}

p {
  line-height: 1.3;
  margin-bottom: 1.3rem;
}

.lead {
  color: var(--rhein-dark);

  font-size: 1.5rem;
  line-height: 1.3;
}

.lead-text {
  font-weight: bold;
}

a {
  color: var(--rhein);
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.prose {
  h3 {
    margin-top: var(--block-margin);
  }
}
