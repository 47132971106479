details {
  --collapsed: auto;
  --expanded: auto;
  height: var(--collapsed);
  overflow: hidden;
  transition: height 0.3s ease-out;
}

details[open] {
  height: var(--expanded);
}

summary {
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }
}

.faq__details {
  display: block;
  padding: 1rem 1.5rem;
  /* background: linear-gradient(to top, var(--gray-light), transparent 3px); */
  box-shadow: inset 0px -10px 10px -10px var(--gray-light);
}

.faq__details:last-of-type {
  box-shadow: unset;
}

h2 + .faq__details,
.faq__details:first-of-type {
  .faq__summary {
    border-top: none;
  }
}

h3.faq__question {
  margin: 0 var(--icon-size) 0 0;
  text-align: left;
}

.faq__summary {
  padding: 1em 0;
  position: relative;
}

.expand-icon__container {
  --icon-size: 2em;

  position: absolute;
  top: calc((100% - var(--icon-size)) / 2);
  right: 0;

  transition: opacity 0.3s ease-out;
}
.expand-more {
  opacity: 1;
}
.expand-less {
  opacity: 0;
}
details[open] .expand-icon__container.expand-more {
  opacity: 0;
}
details[open] .expand-icon__container.expand-less {
  opacity: 1;
}

.faq {
  --icon-color: var(--gray-darker);
  background-color: var(--white);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px var(--gray-light);
  margin-block: var(--block-margin);
}
