.activities__filter-container {
  justify-content: left;
  --grid-gap: 0.2rem;

  margin-bottom: var(--block-margin);
}

.activities {
  --grid-gap: 1rem;
  --grid-columns: 1;
  @media (--media-md) {
    --grid-columns: 2;
  }
  @media (--media-lg) {
    --grid-columns: 3;
  }

  .grid {
    grid-template-columns: repeat(
      var(--grid-columns),
      minmax(var(--grid-item), 1fr)
    );
  }
}

.past-activities .activities__activities-container {
  --grid-columns: 1;
  @media (--media-md) {
    --grid-columns: 3;
  }
  @media (--media-lg) {
    --grid-columns: 4;
  }

  .grid {
    grid-template-columns: repeat(
      var(--grid-columns),
      minmax(var(--grid-item), 1fr)
    );
  }
}

.activity:not(.map-marker) {
  background: var(--white);

  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
}

.activity__image {
  height: 14rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.activity__info {
  padding: 1rem 1.5rem 1.75rem;
  flex-grow: 1;

  p {
    margin-bottom: 0;
  }
  h3 {
    line-height: 1.2;
  }

  .activity__info__details {
    color: var(--lachs);
    justify-content: left;

    margin-bottom: 0.2rem;
    padding: 1rem 0;

    --icon-size: 1rem;
    --icon-color: var(--lachs);

    --grid-gap: 0.5rem;

    > .flex-grid {
      justify-content: left;
      align-items: center;
      --grid-gap: 0.25rem;
    }

    span {
      line-height: 1.2;
    }
  }
}

.activity__footer {
  border-top: 1px solid var(--gray);

  display: flex;
  justify-content: space-between;
  align-items: center;
  .activity__some {
    padding: 0.5rem 1rem;
    --grid-gap: 0.25rem;

    a {
      display: flex;
      align-items: center;

      .icon {
        &:hover,
        &:focus-visible {
          --icon-color: var(--lachs);
        }
      }
    }
  }

  .activity__arrow {
    --button-color: var(--lachs);
    --button-color-contrast: var(--white);

    .icon {
      transform: translateX(0.7rem);
    }

    &:hover,
    &:focus-visible {
      background: var(--lachs-light);
      color: var(--white);
      --icon-color: var(--white);
    }
  }
}

.past-activities {
  .activity__arrow {
    padding: 0.5rem 1rem;
    span {
      display: none;
    }
    .icon {
      transform: none;
    }
  }
}

.activity.featured {
  background: var(--rhein);
  &,
  h3 {
    color: var(--white);
  }

  .activity__some {
    .icon {
      --icon-color: var(--white);

      &:hover,
      &:focus-visible {
        --icon-color: var(--rhein-dark);
      }
    }
  }
  .activity__info__details {
    span {
      color: var(--rhein-dark);
    }
    .icon {
      --icon-color: var(--rhein-dark);
    }
  }
  .activity__footer {
    border-color: var(--rhein-dark);
  }
  .activity__arrow {
    --button-color: var(--rhein-dark);
    &:hover,
    &.active,
    &:focus-visible {
      background: var(--white);
      color: var(--rhein);
      --icon-color: var(--rhein);
    }
  }
}

.activity-detail {
  .lead-image {
    margin-bottom: var(--block-margin);
  }
}

.instafeed {
  position: relative;
  height: 20rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/static/assets/Instafeed.jpg);
}
.instafeed__text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  a {
    display: flex;
    align-items: center;
    font-size: 4rem;
    color: var(--white);
    --icon-size: 4rem;
    --icon-color: var(--white);
    text-shadow: 0 0 2.5rem var(--black);
    .icon {
      margin-right: 0.5rem;
    }
  }
}
