#newsletter-app {
  margin-top: var(--block-margin);
  margin-bottom: var(--block-margin);
  .button {
    &:hover,
    &:focus-visible {
      background: var(--lachs-light);
      color: var(--white);
      border-color: transparent;
    }
  }
}

#messages-app .custom-form {
  transform: translateY(-4rem);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#operations-form-app {
  .custom-form {
    padding: 1rem 2rem 4rem 2rem;
  }
  h2 {
    font-family: PragatiNarrow;
    color: var(--white);
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    text-align: left;
    font-weight: normal;
    line-height: 1.2;
  }
}

#messages-app,
#operations-form-app {
  .custom-form {
    background: var(--lachs);

    .form-group__submit .button {
      --button-color: var(--lachs-dark);
      &:hover,
      &:focus-visible {
        background: var(--lachs-light);
        color: var(--white);
        border-color: transparent;
      }
    }

    .intro-text {
      color: var(--white);
      font-size: 1.3rem;
      line-height: 1.3;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .hint,
    label {
      color: var(--white);
    }
  }
  .success-message {
    font-size: 2rem;
    font-weight: bold;
    font-family: PlayfairDisplay;
    color: var(--white);

    margin-top: 2rem;
    /* margin-bottom: 2rem; */
  }
}

.custom-form {
  position: relative;
  justify-content: space-between;
  --grid-gap: 0.5rem;

  background: var(--white);

  padding: 1rem 1rem 4rem;
  &.submitted {
    padding: 1rem;
  }

  .hint {
    width: 100%;
  }

  fieldset {
    width: 100%;
    padding: 0;
    margin: 0;

    &.half {
      @media (--media-md) {
        flex: 0.5;
      }
    }
    flex: 1 0 90%;
    border: none;
  }

  input:not([type="checkbox"]),
  select,
  textarea {
    background: var(--white);
    border: 1px solid var(--gray-dark);
    width: 100%;
    padding: 0.2rem 0.6rem;
    border-radius: 5px;
  }

  label {
    margin: 0 var(--grid-gap) var(--grid-gap) 0;
  }

  .form-group__submit {
    margin: 0 !important;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .button {
      width: 100%;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: right;

      font-size: 1.2rem;

      .icon,
      span {
        transform: translateX(1rem);
      }

      --icon-color: var(--white);
      --icon-size: 1rem;

      .icon {
        margin-left: 0.5rem;
      }
    }
  }
}
