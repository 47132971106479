.short-infos {
  background: var(--rhein);
  color: var(--white);
  font-size: 1rem;

  h2,
  span,
  a {
    color: inherit;
  }

  h2 {
    font-family: PragatiNarrow;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    text-align: left;
    font-weight: normal;
    padding: 1rem 2rem 0 2rem;
  }

  .short-infos__list {
    padding: 0 2rem;
  }

  dd,
  dt {
    line-height: 1.3;
  }

  dt {
    display: flex;
    align-items: center;

    color: var(--rhein-dark);
    --icon-color: var(--rhein-dark);
    --icon-size: 1rem;

    .icon {
      margin-right: 0.5rem;
    }
  }

  dd {
    margin-bottom: 1rem;
  }
}

.right-column > * {
  margin-bottom: 1.5rem;
}

.person-card {
  display: flex;
  .person-card__info {
    padding: 1rem;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: var(--rhein);
      text-decoration: none;
      &:hover,
      &:focus-visible {
        text-decoration: underline;
      }
    }
  }
}

.short-infos__footer-links {
  display: flex;
  border-top: 1px solid var(--rhein-dark);
  justify-content: space-between;
  .short-infos__some {
    display: flex;
    padding: 0.5rem 2rem;

    a {
      display: flex;
      align-items: center;
    }

    .icon {
      --icon-size: 1.5rem;
      --icon-color: var(--white);

      &:hover,
      &:focus-visible {
        --icon-color: var(--rhein-dark);
      }
    }
  }

  .button--arrow {
    --button-color: var(--rhein-dark);
    --button-color-contrast: var(--white);
  }
}
